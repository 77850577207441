import React from 'react'
import Heading from '../../../components/shared/heading'
import { SectionTitleWrap } from "../../company/join-our-team/join-our-team.stc"
import { AboutSectionWrap, SectionInnerLeft, SectionInnerRight } from '../web-development/about.stc'
import PropTypes from "prop-types"
import { StaticImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql, navigate } from "gatsby"
import Button from "../../../components/shared/button";


//queries the serviceswebdata.json file
const Section = ({ HeadingStyle, SubHeadingStyle, Paragraph, HeadingStyleServies }) => {
    const servicesQueryData = useStaticQuery(graphql`
        query ServicesApiDevelopmentDataQuery {
            servicesapidevelopmentdataJson(jsonId: {eq: "api-development-content"}) {
                apidevelopmentServices {
                    title
                    classId
                    link
                    description
                    tableHeader
                    tableData
                  image {
                        childImageSharp {
                            fluid(maxWidth: 960, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    } 
                }
            }
        }    
    `);
    // const serviceData = servicesQueryData.servicesapidevelopmentdataJson;
    // const databaseServices = serviceData.apidevelopmentServices;

    // const databaseContent = databaseServices.map((databaseService, index) =>
    //     <div className="col-1 offset-1" key={index}>
    //         <a style={{ color: "black" }} href={databaseService.link}>{databaseService.title}</a>
    //     </div>
    // );

    // const content = databaseServices.map((databaseService, index) =>
    //     <div className="row services" key={index} id={databaseService.classId}>
    //         <div className="col-2 offset-1">
    //             <SectionInner style={{ paddingRight: "10vh" }}>
    //                 <SectionTitleWrap>
    //                     <Heading {...HeadingStyleServies}>{databaseService.title}</Heading>
    //                     {databaseService.description.map((textData, i) => <Text key={`story-text-${i}`} {...Paragraph}>{textData}</Text>)}
    //                 </SectionTitleWrap>
    //             </SectionInner>
    //         </div>
    //         <div className="col-2">
    //             <SectionInner>
    //                 {databaseService.image ?
    //                     <Fade right>
    //                         <img src={databaseService.image.childImageSharp.fluid.src} alt={databaseService.title} />
    //                     </Fade>
    //                     :
    //                     <Fade right>
    //                         <SectionTitleWrap>
    //                             {databaseService.tableHeader && <Heading {...SubHeadingStyle}>{databaseService.tableHeader}</Heading>}
    //                             <div className="row">
    //                                 {databaseService.tableData.map((textData, i) =>
    //                                     <div className="col-1" key={`story-text-${i}`}>
    //                                         <Text {...Paragraph}>{textData}</Text>
    //                                     </div>)
    //                                 }
    //                             </div>
    //                         </SectionTitleWrap>
    //                     </Fade>
    //                 }

    //             </SectionInner>
    //         </div>
    //     </div>
    // );

    return (
        <AboutSectionWrap>
            <div className="row service-top-section">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading {...HeadingStyle}>API Services</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <StaticImage src={"../../../../src/data/images/dataAPI.jpeg"} alt="database header"/>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap style={{ marginTop: 60 }} >
                        <Heading className="api-development-integration" {...HeadingStyleServies}>API Development and Integration</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <h3>
                            We can connect you with internal and external data sources securely.
                        </h3>
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>

            {/*<div className="row services">*/}
            {/*    <div className="col-4 offset-1">*/}
            {/*        <SectionInner>*/}
            {/*            <SectionTitleWrap>*/}
            {/*                <Heading {...SubHeadingStyle}>API Development Services</Heading>*/}
            {/*            </SectionTitleWrap>*/}
            {/*        </SectionInner>*/}
            {/*    </div>*/}
            {/*    {databaseContent}*/}
            {/*</div>*/}
            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading {...HeadingStyleServies}>Sync Data In Real-Time or On Schedule</Heading>
                        <br></br>
                        {/*<h3 style={{ width: "70%" }}>Making sense of your data is hard enough. It lives in disparate systems and it's stored in different formats.</h3>*/}
                        <p style={{ width: "80%", fontSize: 20 }}>Sync data from a multitude of systems in real-time or asynchronously
                        </p>
                        <Button
                            layout={2}
                            onClick={() => { navigate("/contact") }}
                            style={{ backgroundColor: 'green', color: 'white' }}
                        >
                            See How <i className="ti-arrow-right"></i>
                        </Button>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <StaticImage className="api-service-image" src={"../../../../src/data/images/sync.jpeg"} alt="ai header"/>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading className="api-service-image" {...HeadingStyleServies}>HTTPS Endpoints</Heading>
                        <br></br>
                        {/*<h3 style={{ width: "70%" }}>Making sense of your data is hard enough. It lives in disparate systems and it's stored in different formats.</h3>*/}
                        <p style={{ width: "70%", fontSize: 20 }}>Stand-up secure endpoints for delivering and receiving 3rd party data communications with secure protocols.
                        </p>
                        <Button
                            layout={2}
                            onClick={() => { navigate("/contact") }}
                            style={{ backgroundColor: 'orange', color: 'white' }}
                        >
                            Explore Options <i className="ti-arrow-right"></i>
                        </Button>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <StaticImage className="api-service-image"  src={"../../../../src/data/images/cloudendpoint.png"} alt="ai header"/>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft className="ingest-write-data">
                    <SectionTitleWrap>
                        <Heading {...HeadingStyleServies}>Ingest and Write Data from APIs to Databases</Heading>
                        <br></br>
                        {/*<h3 style={{ width: "70%" }}>Making sense of your data is hard enough. It lives in disparate systems and it's stored in different formats.</h3>*/}
                        <p style={{ width: "70%", fontSize: 20 }}>Setup background processes to programmatically ingest and store data.
                        </p>
                        <Button
                            layout={2}
                            onClick={() => { navigate("/contact") }}
                            style={{ backgroundColor: '#4115B7', color: 'white' }}
                        >
                            Get Started <i className="ti-arrow-right"></i>
                        </Button>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <StaticImage className="api-service-ingest-image"w src={"../../../../src/data/images/cloudtechdata.jpeg"} alt="ai header"/>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap style={{ marginBottom: 20 }}>
                        <Heading className="run-scheduled-data" {...HeadingStyleServies}>Run Scheduled Data Synchronisation</Heading>
                        <br></br>
                        {/*<h3 style={{ width: "70%" }}>Making sense of your data is hard enough. It lives in disparate systems and it's stored in different formats.</h3>*/}
                        <p style={{ width: "70%", fontSize: 20 }}>Run data jobs and store it on a scheduled basis at regular intervals via cron jobs.
                        </p>
                        <Button
                            layout={2}
                            onClick={() => { navigate("/contact") }}
                        >
                            Run Today <i className="ti-arrow-right"></i>
                        </Button>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <StaticImage className="run-scheduled-image" src={"../../../../src/data/images/infrastructure.jpeg"} alt="ai header"/>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading {...HeadingStyleServies}>GCP Cloud Functions</Heading>
                        <br></br>
                        {/*<h3 style={{ width: "70%" }}>Making sense of your data is hard enough. It lives in disparate systems and it's stored in different formats.</h3>*/}
                        <p style={{ width: "70%", fontSize: 20 }}>Cloud Functions allows you to trigger your code on demand via your frontend, on events, from your backend and via 3rd party events.
                        </p>
                        <p style={{ width: "70%", fontSize: 20 }}>Callable directly from any web or mobile app or backend application if approved and via HTTPS.
                        </p>
                        <h4>Why Cloud Functions.
                        </h4>
                        <ul>
                            <li>Fast, simple deployment</li>
                            <li>Easily extendable</li>
                            <li>Role based security and permissions</li>
                        </ul>
                        <Button
                            layout={2}
                            onClick={() => { navigate("/contact") }}
                            style={{ backgroundColor: "green", color: "white" }}
                        >
                            Run Today <i className="ti-arrow-right"></i>
                        </Button>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight style={{ marginBottom: 80 }}>
                    <StaticImage className="cloud-function-image" src={"../../../../src/data/images/cloud-function-gcp.png"} alt="ai header" />
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading {...HeadingStyleServies}>AWS Lambda</Heading>
                        <br></br>
                        {/*<h3 style={{ width: "70%" }}>Making sense of your data is hard enough. It lives in disparate systems and it's stored in different formats.</h3>*/}
                        <p style={{ width: "70%", fontSize: 20 }}>AWS Lambda is a compute service that lets you run code without provisioning or managing servers.
                        </p>
                        <p style={{ width: "70%", fontSize: 20 }}>Why Lambda?
                        </p>
                        <ul>
                            <li>Serverless microservices architecture</li>
                            <li>Modular and decoupled</li>
                            <li>Security and Scalability</li>
                        </ul>
                        <Button
                            layout={2}
                            onClick={() => { navigate("/contact") }}
                        >
                            Run Today <i className="ti-arrow-right"></i>
                        </Button>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <StaticImage style={{ marginBottom: 40 }} className="aws-lambda-image" src={"../../../../src/data/images/aws-lambda.png"} alt="ai header"/>
                </SectionInnerRight>
            </div>

            {/*{content}*/}

        </AboutSectionWrap>
    )
}
Section.propTypes = {
    HeadingStyle: PropTypes.object,
    SubHeadingStyle: PropTypes.object,
    Paragraph: PropTypes.object,
    HeadingStyleServies: PropTypes.object,
    databaseServiceLeft: PropTypes.object
}

Section.defaultProps = {
    HeadingStyle: {
        fontSize: '60px',
        texttransform: 'capitalize',
        lineHeight: '60px',
        color: '#e4e4e4',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    },
    SubHeadingStyle: {
        fontSize: '30px',
        lineHeight: '30px',
        color: 'primary',
        fontweight: 300,
        responsive: {
            medium: {
                fontSize: '24px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '25px',
                lineHeight: '35px'
            }
        }
    },
    Paragraph: {
        color: 'black',
        fontSize: '20px'
    },
    HeadingStyleServies: {
        fontSize: '50px',
        texttransform: 'capitalize',
        lineHeight: '50px',
        color: 'primary',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    }
}

export default Section

