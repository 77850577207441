import React from "react"
import SEO from '../../components/seo'
import Layout from '../../containers/layout/layout'
import Section from '../../containers/services/api-development'

export default () => (
    <Layout headerLayout={2}>
        <SEO title="Atlasopen - Modern API Development" description="We provide services for API development. Speak to us today. "/>
        {/*<Banner/>*/}
        {/*<About/>*/}
        <Section/>
        {/*<JoinOurTeam/>*/}
        {/*<Testimonial/>*/}
    </Layout>
)

